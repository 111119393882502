import { createStore } from '@qcx/store';

export interface AuthModalStoreState {
  mode: 'signin' | 'signup';
  redirectUrl: string;
  email?: string;
  code?: string;
  isOpen: boolean;
  defaultReturnUrl?: string;
  successCallback?: (params: {
    user: { id: string; name: string; email: string };
  }) => void;
}

const initialState: AuthModalStoreState = {
  mode: 'signup',
  isOpen: false,
  redirectUrl: ''
};

const { Provider, useStore } = createStore(initialState, ({ set }) => ({
  toggle: (mode: AuthModalStoreState['mode']) => {
    set(s => ({ mode, isOpen: !s.isOpen }));
  },
  close: () => {
    set({ isOpen: false });
  },
  open: (
    mode: AuthModalStoreState['mode'],
    redirectUrl?: AuthModalStoreState['redirectUrl']
  ) => {
    set({ mode, redirectUrl, isOpen: true });
  },
  changeMode: (mode: AuthModalStoreState['mode']) => {
    set({ mode });
  },
  setEmail: (email: string) => {
    set({ email });
  },
  setCode: (code: string) => {
    set({ code });
  }
}));

export const AuthModalStoreProvider = Provider;
export const useAuthModalStore = useStore;
